<template>
	<div>
		<div class="header-section icon-list detailed">
            <div class="header-with-tabs row-space-tbf">
            	<div class="space-left"></div>
                <div class="content">
					<slot />
                    <div class="actions actions-dropdown" v-if="soft_procedures.is_active">
                        <div v-if="$resize && $mq.above(781)" class="filter-dropdown dropdown columns">
                            <button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="text" v-if="filteredColumns.filter(el => el.value).length == 12">{{ $t('reports.columns_config') }}</span>
                                <span class="text" v-else-if="filteredColumns.filter(el => el.value).length == 1">{{ filteredColumns.find(el => el.value).name }}</span>
                                <span class="text" v-else>{{ filteredColumns.filter(el => el.value).length }} {{ $t('filters.columns_selected') }}</span>

                                <div class="icon-filter"><icon-arrow /></div>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownFilter">
                                <div class="search-filter-dropdown">
                                    <input type="text" v-model="searchRoles" :placeholder="$t('reports.search_column')">
                                </div>
                            
                                <template v-for="(column, index) in filteredColumns">
                                    <div class="dropdown-item" @click.stop="selectOptionColumnFilter(index, column.value)" v-bind:class="{active: column.value}" :key="'filter-column-' + index">
                                        <div class="checkbox">
                                            <div class="checkmark"></div><span class="text">{{ column.name }}</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
						<div class="actions-page mobile">
							<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
								<span class="text">{{ $t('general.filters') }}</span>
								<div class="icon-filter">
									<icon-arrow />
								</div>
							</button>
							<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="icon">
									<icon-settings />
								</div>
							</button>
						</div>
						<button class="btn-tbf blue btn-excel center" v-bind:class="{'only-icon': $resize && $mq.below(1299)}" @click="donwloadXLS">
                            <span class="text" v-if="$resize && $mq.above(1300)">{{ $t('reports.download_xls') }}</span>
                            <icon-download class="icon" v-else />
                        </button>
                    </div>
                </div>
                <div class="space-right"></div>
            </div>
		</div>
		
		<template v-if="soft_procedures.is_active">
			<div class="header-filter" :class="[{'filter-active': extendFilters}]" v-if="loaded">
				<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.between([781, 905]) ||  $resize && $mq.between([0, 682])}]" v-if="extendFilters">
					<div class="space-left"></div>
					<div class="content">
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
								<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
								<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

								<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
								</div>

								<template v-for="role in filteredRoles">
									<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ role.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
								<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find(el => el.id == selectedUsers[0]).name }}</span>
								<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.responsibles_selected') }}</span>

								<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
							</button>

							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')">
								</div>

								<template v-for="user in filteredUsers">
									<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-user-' + user.id">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ user.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<div class="filter-dropdown dropdown">
							<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text" v-if="!selectedCategories.length">{{ $t('filters.by_categories') }}</span>
								<span class="text" v-else-if="selectedCategories.length == 1">{{ filterCategories.find(el => el.id == selectedCategories[0]).name }}</span>
								<span class="text" v-else>{{ selectedCategories.length }} {{ $t('filters.categories_selected') }}</span>

								<div class="icon-filter" v-if="!selectedCategories.length"><icon-arrow /></div>
								<div class="icon-clear" @click.stop="clearFilter('selectedCategories')" v-else><icon-close /></div>
							</button>

							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="search-filter-dropdown">
									<input type="text" v-model="searchCategories" :placeholder="$t('filters.search_categories')">
								</div>

								<template v-for="category in filteredCategories">
									<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedCategories', category.id)" v-bind:class="{active: selectedCategories.includes(category.id)}" :key="'filter-user-' + category.id">
										<div class="checkbox" :class="`level-${category.level_depth}`" :style="`padding-left: calc(10px * ${category.level_depth});`">
											<div class="checkmark"></div><span class="text">{{ category.name }}</span>
										</div>
									</div>
								</template>
							</div>
						</div>
						<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
							<icon-trash v-if="$resize && $mq.above(905) || $resize && $mq.between([682, 780])"/>
							<div v-else-if="$resize && $mq.between([781, 905]) ||  $resize && $mq.between([0, 682])">{{$t('filters.clear_all')}}</div>
						</button>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf search-row with-border">
					<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div class="search-input">
							<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
			<loader-header-list-without-btn v-else/>

			<template v-if="loadedList">
				<div class="data-list list-procedures" v-if="procedures.length">
					<div class="row-space-tbf header-list">
						<div class="space-left"></div>
						<div class="content">
							<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy === 'name' ? 'sort ' + sortDirection : '']">
								<div class="text">{{ $t('procedures.name') }}</div>
								<div class="icon-filter"><icon-arrow /></div>
							</div>
							<div v-if="procedures_column[0].value && $resize && $mq.above(480)" class="column-filter column-entries">
								<div class="text">{{ $t('procedures.visits') }}</div>
							</div>
							<div v-if="procedures_column[11].value && $resize && $mq.above(480)" class="column-filter column-trackings">
								<div class="text">{{ $t('reports.procedure_checks_last_30_days') }}</div>
							</div>
							<div v-if="procedures_column[1].value && $resize && $mq.above(580)" class="column-filter column-updates">
								<div class="text">{{ $t('procedures.updates') }}</div>
							</div>
							<div v-if="procedures_column[2].value && $resize && $mq.above(830)" class="column-filter sortable column-last-update" @click="sortList('updated_at')" v-bind:class="[sortBy === 'updated_at' ? 'sort ' + sortDirection : '']">
								<div class="text">{{ $t('general.last_update') }}</div>
								<div class="icon-filter"><icon-arrow /></div>
							</div>
							<div v-if="procedures_column[3].value" class="column-filter column-reading-time">
								<div class="text">{{ $t('procedures.reading_time') }}</div>
							</div>
							<div v-if="procedures_column[4].value" class="column-filter column-total_time">
								<div class="text">{{ $t('procedures.total_time') }}</div>
							</div>
							<div v-if="procedures_column[5].value && $resize && $mq.above(480)" class="column-filter column-avg-time">
								<div class="text">{{ $t('procedures.average_time') }}</div>
							</div>
							<div v-if="procedures_column[6].value && $resize && $mq.above(935)" class="column-filter column-count-improvements">
								<div class="text">{{ $t('procedures.improvement_requests') }}</div>
							</div>
							<div v-if="procedures_column[7].value" class="column-filter column-count-steps">
								<div class="text">{{ $t('procedures.steps_cnt') }}</div>
							</div>
							<div v-if="procedures_column[8].value" class="column-filter column-roles">
								<div class="text">{{ $t('procedures.roles') }}</div>
							</div>
							<div v-if="procedures_column[9].value" class="column-filter column-count-triggers">
								<div class="text">{{ $t('procedures.triggers') }}</div>
							</div>
							<div v-if="procedures_column[10].value" class="column-filter column-responsible">
								<div class="text">{{ $t('procedures.responsible') }}</div>
							</div>
							<div class="column-filter column-user"></div>
						</div>
						<div class="space-right"></div>
					</div>

					<template v-for="procedure in procedures">
						<div class="row-space-tbf row-list-item" :key="procedure.id">
							<div class="space-left"><icon-procedures v-if="$resize && $mq.above(781)"/></div>
							<div class="content">
								<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
								<div class="column-name-desc column-name">
									<div class="name" @click="showProcedure(procedure.slug)">{{ procedure.name }}</div>
								</div>
								<div v-if="procedures_column[0].value && $resize && $mq.above(480)" class="column-simple-text column-entries">
									<div class="text" :class="{entries: procedure.rights.entity_crud}" @click="procedure.rights.entity_crud ? showModal('entries_procedure_users', procedure.slug) : ''">{{ procedure.analytics.entries }}</div>
								</div>
								<div v-if="procedures_column[11].value && $resize && $mq.above(480)" class="column-simple-text column-trackings">
									<div class="text" :class="{entries: procedure.rights.entity_crud}" @click="procedure.rights.entity_crud ? showModal('report_procedures_checks', {slug: procedure.slug, type: 'procedure', name: procedure.name}) : ''">{{ procedure.procedure_checks.run_checks }}</div>
								</div>
								<div v-if="procedures_column[1].value && $resize && $mq.above(580)" class="column-simple-text column-updates">
									<div class="text">{{ procedure.total_procedure_histories }}</div>
								</div>
								<div v-if="procedures_column[2].value && $resize && $mq.above(830)" class="column-simple-text column-last-update">
									<div class="text">{{ procedure.updated_at | moment('DD MMM YYYY') }}</div>
								</div>
								<div v-if="procedures_column[3].value" class="column-simple-text column-reading-time">
									<div class="text">{{readingTime(procedure.steps.map(el => [el.body]).join(" "))}}</div>
								</div>
								<div v-if="procedures_column[4].value" class="column-simple-text column-total_time">
									<div v-if="procedure.analytics" class="text">{{ procedure.analytics.total_time }}</div>
								</div>
								<div v-if="procedures_column[5].value && $resize && $mq.above(480)" class="column-simple-text column-avg-time">
									<div v-if="procedure.analytics" class="text">{{ procedure.analytics.average_time }}</div>
								</div>
								<div v-if="procedures_column[6].value && $resize && $mq.above(935)" class="column-simple-text column-count-improvements">
									<div v-if="procedure.total_improvement_proposals" class="text">{{ procedure.total_improvement_proposals }}</div>
								</div>
								<div v-if="procedures_column[7].value" class="column-simple-text column-count-steps">
									<div v-if="procedure.steps" class="text">{{ procedure.steps.length }}</div>
								</div>
								<div v-if="procedures_column[8].value" class="column-simple-text column-roles">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
										<div class="text roles">
											<span class="first" v-if="procedure.roles.length == 1">{{ procedure.roles[0] }}</span>
											<span v-else-if="procedure.roles.length > 1">{{`${procedure.roles[0]} (+${(procedure.roles.length - 1)})`}}</span>
										</div>
										<template slot="popover">
											<div class="simple-text"><span>{{ procedure.roles.map((el) => { return el}).join(", ") }}</span></div>
										</template>
									</v-popover>
								</div>
								<div v-if="procedures_column[9].value" class="column-simple-text column-count-triggers">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
										<div class="text roles">
											<template >
												<span v-if="procedure.triggers.length > 0">{{ procedure.triggers.length }}</span>
											</template>
										</div>
										<template slot="popover">
											<div class="simple-text"><span v-for="(trigger, index) in procedure.triggers">{{index < procedure.triggers.length - 1 ? $t('trigger.' + trigger.type) + ': ' + trigger.value + ', ' : $t('trigger.' + trigger.type) + ': ' + trigger.value}}</span></div>
										</template>
									</v-popover>
								</div>
								<div v-if="procedures_column[10].value" class="column-simple-text column-responsible">
									<div v-if="procedure.responsible" class="text">{{ procedure.responsible.name }}</div>
								</div>
								<div class="column-avatar column-user">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="procedure.responsible">
										<img :src="procedure.responsible.avatar" v-if="procedure.responsible && procedure.responsible.avatar">
										<div class="user-circle" v-else>
											<icon-user />
										</div>
										<template slot="popover">
											<div class="simple-text">{{ procedure.responsible.name }}</div>
										</template>
									</v-popover>
								</div>
							</div>
							<div class="space-right">
								<!-- <div v-if="$resize && $mq.above(781)" class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+procedure.id" :id="'dropdownEdit'+procedure.id" data-toggle="dropdown">
										<icon-edit />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ procedure.id " :aria-labelledby="'dropdownEdit'+procedure.id">
										<div class="dropdown-item" @click="editProcedure(procedure.slug)"><div class="simple-text">{{ $t('general.edit') }}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'procedure', from: 'index_procedures', model: procedure})"><div class="simple-text">{{ $t('general.delete') }}</div></div>
									</div>
								</div> -->
							</div>
						</div>
					</template>
				</div>
				<div class="row-space-tbf list-empty" v-else>
					<div class="space-left"></div>
					<div class="content full">
						<!-- <div class="icon-empty">
							<img src="/build/images/no-results-found.svg">
						</div> -->
						<div class="title">{{ $t('empty.report-title')}}</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
			<loader-items-list class="position-absolut-list-loader" v-else/>

			<infinite-loading :identifier="infiniteId" @infinite="infiniteProcedures" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
		</template>
		<template v-else>
			<div class="row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<not-module class="with-border-top" />
				</div>
				<div class="space-right"></div>
			</div>
		</template>
	</div>
</template>

<script>
	import NotModule from '@/components/General/NotModule'
    import IconArrow from '../../Icons/Arrow'
    import IconUser from '../../Icons/User'
    import IconPeople from '../../Icons/People'
    import IconClose from '../../Icons/Close'
    import IconEdit from '../../Icons/EditDots'
    import IconProcedures from '../../Icons/Procedures'
	import IconSearch from '../../Icons/Search'
	import IconDownload from '../../Icons/Download'
	import IconSettings from '../../Icons/Settings'
	import IconTrash from '../../Icons/Trash'
    import LoaderHeaderListWithoutBtn from '../../PagesLoaders/HeaderListWithoutBtn'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
			NotModule,
            IconArrow,
            IconUser,
            IconPeople,
            IconClose,
            IconEdit,
			IconProcedures,
			IconDownload,
			IconSearch,
			IconSettings,
			IconTrash,
			LoaderItemsList,
			LoaderHeaderListWithoutBtn,
			InfiniteLoading
        },
		watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        computed: {
            filteredColumns(){
				return getByKeywordFilter(this.procedures_column, this.searchRoles)
			},
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredUsers(){
				return getByKeywordFilter(this.filterUsers, this.searchUsers)
			},
			filteredCategories(){
				return getByKeywordFilter(this.filterCategories, this.searchCategories)
			},
			soft_procedures() {
				return this.$store.getters['applications/getApplication']('Proceduri');
			}
        },
        data() {
            return {
            	loaded: false,
            	loadedList: false,
            	procedures: [],
            	sortBy: '',
                searchRoles: '',
            	sortDirection: 'asc',
                procedures_column: [
                    {
                        name: this.$t('procedures.visits'),
                        entity: 'analytics.entries',
                        value: true
                    },
                    {
                        name: this.$t('procedures.updates'),
                        entity: 'total_procedure_histories',
                        value: true
                    },
                    {
                        name: this.$t('procedures.last_update'),
                        entity: 'updated_at',
                        value: true
                    },
                    {
                        name: this.$t('procedures.reading_time'),
                        value: false
                    },
                    {
                        name: this.$t('procedures.total_time'),
                        entity: 'analytics.total_time',
                        value: false
                    },
                    {
                        name: this.$t('procedures.average_time'),
                        entity: 'analytics.average_time',
                        value: true
                    },
                    {
                        name: this.$t('procedures.improvement_requests'),
                        entity: 'total_improvement_proposals',
                        value: true
                    },
                    {
                        name: this.$t('procedures.steps_cnt'),
                        entity: 'steps',
                        value: false
                    },
                    {
                        name: this.$t('procedures.roles'),
                        entity: 'roles',
                        value: false
                    },
                    {
                        name: this.$t('procedures.triggers'),
                        entity: 'triggers',
                        value: false
                    },
                    {
                        name: this.$t('procedures.responsible'),
                        entity: 'responsible.name',
                        value: false
                    },
                    {
                        name: this.$t('procedures.trackings'),
                        entity: 'procedure_checks.run_checks',
                        value: true
                    },
                ],
				page: 0,
				infiniteId: 1,
				search_filter: '',
            	queryFiltersCount: 0,
            	extendFilters: false,
				filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',
				selectedUsers: [],
				searchUsers: '',
				selectedCategories: [],
				filterCategories: [],
				searchCategories: '',
            }
        },
        async mounted(){
        	if( this.soft_procedures.is_active ){ 
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			}
			
			await this.getFilters()
			this.checkQueryFilters()

            this.$root.$on('refreshProceduresIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
			})
        },
        methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true, users: true, categories: true}})
				.then(({data}) => {
        			this.filterUsers = data.data.users
        			this.filterRoles = data.data.roles
					this.filterCategories = data.data.categories
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
            infiniteProcedures($state){
				var paramsCall = { view_more: this.page * 10}
        		this.queryObject(paramsCall)

        		if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

				axios.get('/reports/' + this.$auth.user().instance.id + '/procedures', {params: paramsCall}).then(({data}) => {
                    if(data.data.length) {
                        this.page++
						this.procedures.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 10){
        					$state.complete()
        				}
                    } else{
						$state.complete()
					}

        			this.loadedList = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
            selectOptionColumnFilter(index, value) {
            	this.filteredColumns[index].value = !value
            },
            readingTime(steps){
				const words = steps.replace(/(&nbsp;|&gt;|&lt;|&amp|<br>|<br| \/>|<[^>]*>?)/gm, ' ').trim().split(/\s+/)
				const wpm = 225;
				const time = words.length / wpm
				if(moment.duration(time, 'minutes')._data.minutes > 0 && moment.duration(time, 'minutes')._data.seconds > 0) {
					return moment.duration(time, 'minutes')._data.minutes + ' ' +  this.$t('general.min') + ' ' + moment.duration(time, 'minutes')._data.seconds + ' ' + this.$t('general.sec');
				} else if(moment.duration(time, 'minutes')._data.minutes == 0 && moment.duration(time, 'minutes')._data.seconds > 0) {
					return moment.duration(time, 'minutes')._data.seconds + ' ' + this.$tc('general.sec');
				} else if(moment.duration(time, 'minutes')._data.minutes > 0 && moment.duration(time, 'minutes')._data.seconds == 0) {
					return moment.duration(time, 'minutes')._data.minutes + ' ' + this.$t('general.count_minutes');
				} else if(moment.duration(time, 'minutes')._data.minutes == 0 && moment.duration(time, 'minutes')._data.seconds == 0){
					return '1 ' + this.$t('general.sec');
				}
			},
            showProcedure(itemSlug){
        		this.$router.push({name: 'procedure-show', params: {slug: itemSlug}})
        	},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;
				this.searchFilterFunct()
			},
			editProcedure(procedureSlug){
				this.$router.push({ name: 'procedure-edit', params: {slug: procedureSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
            donwloadXLS() {
                var showColumnsObj = ['name']

                this.procedures_column.filter(el => el.value == true ).map(item => {
                	if(item.entity) {showColumnsObj.push(item.entity)}
                })

                var paramsCall = {}
				this.queryObject(paramsCall)
				paramsCall.type = 'xls'
				paramsCall.show_columns = showColumnsObj

				axios.get('/reports/' + this.$auth.user().instance.id + '/procedures', 
				{
					params : paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = this.$t('reports.procedures') + '.xlsx';

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'users'){ paramsCall.user_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'categories'){ paramsCall.category_ids = dataQuery[key].split(',').map(String) }
				});
			},
        	selectOptionFilter(filter, value) {
        		if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
        	},
        	addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedUsers' || filterName == 'all'){ dataQuery['users'] = this.selectedUsers.toString(); }
				if(filterName == 'selectedCategories' || filterName == 'all'){ dataQuery['categories'] = this.selectedCategories.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 2 : Object.keys(dataQuery).length - 1

				this.$router.push({query : dataQuery });
				this.searchFilterFunct();
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedUsers = []
					this.selectedCategories = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);

				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'users'){ this.selectedUsers = dataQuery[key].split(',').map(Number) }
					if(key == 'categories'){ this.selectedCategories = dataQuery[key].split(',').map(Number) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 2 : Object.keys(dataQuery).length -1
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.procedures = []
				this.infiniteId += 1
			},
        }
    }
    function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss" scoped>
	.list-procedures{
        .view-more{
            width: 100%;
            a{
                width: 100%;
                height: 38px;
                .text{
                    color: $grey;
                }
            }
        }
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-name{
			flex: 1 1 auto;
			.name{
				cursor: pointer;
			}
		}
		.column-reading-time{
			flex: 0 0 120px;
		}
		.column-roles{
			flex: 0 0 150px;
			.roles{
				display: flex;
				span{
					&:last-child{
						padding-left: 4px;
					}
				}
			}
		}
         .column-entries,
         .column-trackings{
			 .text{
				 &.entries {
					cursor: pointer;
					&:hover{
						text-decoration: underline;
					}
				 }
			 }
		 }
        .column-entries,
        .column-updates,
        .column-total_time,
        .column-avg-time,
        .column-count-steps{
			flex: 0 0 100px;
		}
        .column-count-improvements,
        .column-responsible,
        .column-count-triggers,
        .column-trackings {
			flex: 0 0 120px;
		}
        .column-last-update{
        	flex: 0 0 150px;
        }
        
		.column-user{
			flex: 0 0 90px;
			display: flex;
			justify-content: flex-end;
		}
        @media (max-width: 1120px) {
			.column-reading-time,
            .column-entries,
            .column-updates,
            .column-total_time,
            .column-avg-time,
            .column-count-steps,
            .column-count-improvements,
            .column-responsible,
            .column-last-update{
				flex: 0 0 100px;
			}
			.column-user{
				flex: 0 0 37px;
			}
		}
	}
    .filter-dropdown.columns{
        margin-left:10px
    }

    .list-empty .content {
		padding: 10px;
		padding-left: 0px;
		border-top: none;
		color: $grey
	}
	.text.roles{
		span.first{
			flex: 1;
		}
		span:last-child {
			padding: 0 4px;
			align-items: center;
			display: flex;
		}
	}
</style>